import {Box, useMediaQuery, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import React from 'react';
import CustomRow from '../../../../components/atoms/CustomRow';
import CustomView from '../../../../components/atoms/CustomView';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import {Fonts} from '../../../../styles/font';
import SettingIcon from '../../../../assets/images/ordify-bot/setting.svg';
import TelephoneIcon from '../../../../assets/images/ordify-bot/telephone.svg';
import BookIcon from '../../../../assets/images/ordify-bot/book.svg';
import MockupImage from '../../../../assets/images/ordify-bot/mockup.png';
import RestaurantImage from '../../../../assets/images/ordify-bot/restaurant.png';
import {Element} from './Element';

type Props = {};

const fontFamily: Fonts = 'Montserrat';

const elements = [
  {
    icon: SettingIcon,
    titleColor: 'ordifyBot',
    title: 'Imposta i parametri del tuo locale',
    description: 'Seleziona quanti sono i posti a sedere, gli orari di prenotazione e altre info necessarie.',
  },
  {
    icon: TelephoneIcon,
    titleColor: 'black',
    title: 'Reindirizza la chiamata ad un umano',
    description:
      'Se il cliente non volesse parlare con l’intelligenza artificiale di Ordify può avere la scelta di re-indirizzare la chiamata ad un umano che soddisferà ogni sua esigenza.',
  },
  {
    icon: BookIcon,
    titleColor: 'black',
    title: 'Ricevi le prenotazioni in app',
    description:
      'Dopo che il cliente avrà concluso la chiamata, riceverai i dettagli della prenotazione sulla nostra app dedicata. In caso di problemi potrai sempre annullare la prenotazione e il cliente verrà informato tramite SMS.',
  },
];

const useStyles = makeStyles({
  imgContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  imgMockup: {
    position: 'relative',
    width: '370px',
    height: '100%',
    objectFit: 'cover',
    zIndex: 2,
    '@media (max-width: 767px)': {
      width: '250px',
    },
  },
  imgRestaurant: {
    position: 'absolute',
    height: '100%',
    top: 5,
    left: '15%',
    zIndex: 1,
    objectFit: 'cover',
    borderRadius: '0 0 20px 20px',
    '@media (max-width: 767px)': {
      // width: '40%',
    },
  },
});

export const HowItWorks = ({}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box id={'come-funziona'}>
      <CustomView paddingVertical={80} paddingHorizontal={isMobile ? 20 : 80}>
        <CustomRow center marginBottom={16}>
          <CustomText bold fontFamily={fontFamily} size={'xxl'} color={'ordifyBot'} center>
            Automatizza le tue prenotazioni tavolo.
          </CustomText>
        </CustomRow>
        <CustomRow center marginBottom={16}>
          <CustomText fontFamily={fontFamily} center>
            Ricevere e gestire le prenotazioni tavolo non è mai stato così comodo.
            <br /> &Egrave; molto più facile e intuitivo di quanto pensi
          </CustomText>
        </CustomRow>
        <GridContainer marginTop={60}>
          <GridItem md={6} centerHorizontal direction={'column'}>
            {elements.map(({icon, titleColor, title, description}, index) => (
              <CustomView key={index} marginBottom={20} marginTop={20}>
                <Element icon={icon} title={title} description={description} titleColor={titleColor} />
              </CustomView>
            ))}
          </GridItem>
          <GridItem md={6} marginTop={20} centerHorizontal>
            <div className={classes.imgContainer}>
              <img src={MockupImage} alt={'mockup'} className={classes.imgMockup} />
              <img src={RestaurantImage} alt={'restaurant'} className={classes.imgRestaurant} />
            </div>
          </GridItem>
        </GridContainer>
      </CustomView>
    </Box>
  );
};
