import {Typography} from '@material-ui/core';
import React from 'react';
import {Variant} from '@material-ui/core/styles/createTypography';
import {makeStyles} from '@material-ui/styles';
import * as _ from 'lodash';
import {defaultFont} from '../../../styles/material-kit/nextjs-material-kit';
import classNames from 'classnames';
import {BLACK, colorMap, Colors} from '../../../styles/colors';
import {fontFamilyMap, Fonts} from '../../../styles/font';

const CustomText = ({
  variant = 'inherit',
  children,
  className,
  onClick,
  underline,
  italic,
  bold,
  lineThrough,
  html,
  center,
  right,
  size = 'md',
  color,
  maxWidth,
  style,
  fontFamily = 'Open Sans',
}: Props) => {
  const classes = useStyles({maxWidth, color, fontFamily});
  const baseClassNames = classNames({
    [classes.textDefault]: true,
    [classes.bold]: bold,
    [classes.underline]: underline,
    [classes.italic]: italic,
    [classes.lineThrough]: lineThrough,
    [classes.right]: right,
    [classes.center]: center,
    [classes.pointer]: onClick,
    [classes.textFontFamily]: fontFamily,
    [classes.textColor]: color,
    ...(size && {[classes[`font_${size}`]]: true}),
    ...(className && {[className]: true}),
  });

  const renderTextOrPrice = () => {
    if (
      children instanceof Array &&
      children.length > 0 &&
      children.findIndex((c) => typeof c === 'string' && c.trim() === '€') > -1
    ) {
      const i = children.findIndex((c) => _.isNumber(c));
      let newChildren = [...children];
      if (i > -1) {
        newChildren[i] = newChildren[i].toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      }
      return newChildren;
    }
    return children;
  };

  const customHtmlRender = () => {
    return children.replace(/\n/g, '<br />');
  };

  return (
    <>
      {html ? (
        <Typography
          style={style}
          variant={variant}
          className={baseClassNames}
          onClick={onClick}
          dangerouslySetInnerHTML={{__html: customHtmlRender()}}
        ></Typography>
      ) : (
        <Typography style={style} variant={variant} className={baseClassNames} onClick={onClick}>
          {renderTextOrPrice()}
        </Typography>
      )}
    </>
  );
};

type Props = {
  children: any;
  className?: any;
  onClick?: any;
  variant?: Variant | 'inherit';
  maxWidth?: number;
  bold?: boolean;
  italic?: boolean;
  right?: boolean;
  center?: boolean;
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  color?: Colors;
  fontFamily?: Fonts;
  underline?: boolean;
  lineThrough?: boolean;
  html?: boolean;
  style?: React.CSSProperties;
};

const useStyles = makeStyles({
  textDefault: {
    ...defaultFont,
    maxWidth: (props: any) => (props.maxWidth ? `${props.maxWidth}%` : undefined),
  },
  italic: {
    fontStyle: 'italic',
  },
  fontOpenSans: {
    fontFamily: 'Open Sans',
  },
  underline: {
    textDecoration: 'underline',
  },
  lineThrough: {
    textDecoration: 'line-through',
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  pointer: {
    cursor: 'pointer',
  },
  bold: {
    fontWeight: 700,
  },
  font_xxs: {
    '@media (max-width: 480px)': {
      fontSize: '10px',
    },
    '@media (min-width: 481px) and (max-width: 768px)': {
      fontSize: '10px',
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      fontSize: '10px',
    },
    '@media (min-width: 1025px) and (max-width: 1200px)': {
      fontSize: '10px',
    },
    '@media (min-width: 1201px)': {
      fontSize: '10px',
    },
  },
  font_xs: {
    '@media (max-width: 480px)': {
      fontSize: '12px',
    },
    '@media (min-width: 481px) and (max-width: 768px)': {
      fontSize: '12px',
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      fontSize: '12px',
    },
    '@media (min-width: 1025px) and (max-width: 1200px)': {
      fontSize: '14px',
    },
    '@media (min-width: 1201px)': {
      fontSize: '14px',
    },
  },
  font_sm: {
    '@media (max-width: 480px)': {
      fontSize: '12px',
    },
    '@media (min-width: 481px) and (max-width: 768px)': {
      fontSize: '13px',
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      fontSize: '14px',
    },
    '@media (min-width: 1025px) and (max-width: 1200px)': {
      fontSize: '15px',
    },
    '@media (min-width: 1201px)': {
      fontSize: '16px',
    },
  },
  font_md: {
    fontSize: '18px',
    '@media (max-width: 768px)': {
      fontSize: '16px',
    },
  },
  font_lg: {
    '@media (max-width: 480px)': {
      fontSize: '20px',
      lineHeight: '27px',
    },
    '@media (min-width: 481px) and (max-width: 768px)': {
      fontSize: '24px',
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      fontSize: '30px',
    },
    '@media (min-width: 1025px) and (max-width: 1200px)': {
      fontSize: '30px',
    },
    '@media (min-width: 1201px)': {
      fontSize: '36px',
    },
  },
  font_xl: {
    '@media (max-width: 480px)': {
      fontSize: '30px !important',
    },
    '@media (min-width: 481px) and (max-width: 768px)': {
      fontSize: '30px !important',
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      fontSize: '40px !important',
    },
    '@media (min-width: 1025px) and (max-width: 1200px)': {
      fontSize: '50px !important',
    },
    '@media (min-width: 1201px)': {
      fontSize: '50px !important',
    },
  },
  font_xxl: {
    '@media (max-width: 480px)': {
      fontSize: '40px !important',
      lineHeight: '40px',
    },
    '@media (min-width: 481px) and (max-width: 768px)': {
      fontSize: '40px !important',
      lineHeight: '40px',
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      fontSize: '50px !important',
      lineHeight: '50px',
    },
    '@media (min-width: 1025px) and (max-width: 1200px)': {
      fontSize: '60px !important',
      lineHeight: '60px',
    },
    '@media (min-width: 1201px)': {
      fontSize: '70px !important',
      lineHeight: '80px',
    },
  },
  textFontFamily: ({fontFamily}: Props) => ({fontFamily: fontFamily ? fontFamilyMap[fontFamily] : 'Open Sans'}),
  textColor: ({color}: Props) => ({color: color ? colorMap(color) : BLACK}),
} as any);

export default CustomText;
