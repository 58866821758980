import {HeaderLink} from '../components/organisms/types/header-link';

export const ROOT = '/';
export const HOME = '/home';
export const PRIVACY = '/privacy';
export const LEGAL_TERMS = '/legal-terms';
export const DOWNLOAD_REDIRECT = '/download-redirect';
export const LOGIN = '/login';
export const MAIL_LOGIN = '/mail-login';
export const REGISTRATION = '/registration';
export const VALIDATE_EMAIL = '/validate-email';
export const SUBMIT_PHONE_NUMBER = '/submit-phone-number';
export const VALIDATE_PHONE_NUMBER = '/validate-phone-number';
export const RESET_PASSWORD = '/reset-password';
export const FORGOT_PASSWORD = '/forgot-password';
export const FORGOT_PASSWORD_SET_NEW = '/forgot-password-set-new';
export const SEARCH = '/search-restaurants';
export const ACTIVITY_DETAIL = '/activity-detail';
export const ACTIVITY_DETAIL_MENU = 'menu';
export const ACTIVITY_DETAIL_SELECT_MENU = 'select-menu';
export const COMPLETE_ORDER = '/complete-order';
export const COMPLETE_TABLE_RESERVATION = '/complete-table-reservation';
export const USER_ORDERS = '/user-orders';
export const SMART_MENU = '/smartmenu';
export const ORDIFY_BOT = '/ordify-bot';
export const DELETE_PROFILE_DATA = '/delete-profile-data';

export const HOME_HEADER_LINKS: HeaderLink[] = [
  {
    id: 'chi-siamo',
    label: 'Chi siamo',
    pos: null,
    linked: true,
  },
  {
    id: 'servizi',
    label: 'Servizi',
    pos: null,
    linked: true,
  },
  {
    id: 'sconti',
    label: 'Sconti',
    pos: null,
    linked: true,
  },
  {
    id: 'scarica-app',
    label: "Scarica l'app",
    pos: null,
    linked: true,
  },
  {
    id: 'smartmenu',
    label: 'Smartmenu',
    pos: null,
    linked: true,
  },
];

export const SMART_MENU_HEADER_LINKS: HeaderLink[] = [
  {
    id: 'vantaggi',
    label: 'I Vantaggi',
    pos: null,
    linked: true,
  },
  {
    id: 'interfaccia',
    label: "L'interfaccia",
    pos: null,
    linked: true,
  },
  {
    id: 'come-funziona',
    label: 'Come funziona',
    pos: null,
    linked: true,
  },
  {
    id: 'prezzi',
    label: 'Prezzi',
    pos: null,
    linked: true,
  },
  {
    id: 'parla-con-noi',
    label: 'Parla con noi',
    pos: null,
    linked: true,
  },
];

export const ORDIFY_BOT_HEADER_LINKS: HeaderLink[] = [
  {
    id: 'come-funziona',
    label: 'Come funziona?',
    pos: null,
    linked: true,
  },
  {
    id: 'testa-il-servizio',
    label: 'Testa il servizio',
    pos: null,
    linked: true,
  },
  {
    id: 'vantaggi',
    label: 'Vantaggi',
    pos: null,
    linked: true,
  },
  {
    id: 'dicono-di-noi',
    label: 'Dicono di noi',
    pos: null,
    linked: true,
  },
  {
    id: 'richiedi-prova',
    label: 'Richiedi una prova',
    pos: null,
    linked: true,
  },
];

export * from './allergens';
