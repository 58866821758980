import {useEffect, useState} from 'react';
import {Choice, Option} from '../../../../models/menu/option';
import * as _ from 'lodash';
import {OrderCartItem} from '../../../../models/orders/order-cart-item';
import CustomRow from '../../../../components/atoms/CustomRow';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import CustomButton from '../../../../components/atoms/CustomButtons/CustomButton';
import {CategoryForMenu, Ingredient, ItemForMenu} from '../../../../models/menu/category';
import {makeStyles} from '@material-ui/styles';
import {WHITE} from '../../../../styles/colors';
import ItemOption from './ItemOption';
import ChipList from '../../../../components/molecules/ChipList';
import CustomDialog from '../../../../components/atoms/CustomDialog';
import ModalItemFooter from './ModalItemFooter';

const ModalItem = ({
  style,
  open,
  onClose,
  onConfirm,
  item,
  category,
  itemOrder,
  onRemoveFromOrder,
  hideAddButton = false,
}: Props) => {
  const classes = useStyle();
  const [removedIngredients, setRemovedIngredients] = useState<Ingredient[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (!open) {
      setRemovedIngredients([]);
      setSelectedOptions([]);
    }
    if (itemOrder && open) {
      setSelectedOptions(_.cloneDeep(itemOrder.selectedOptions));
      setRemovedIngredients(_.cloneDeep(itemOrder.removedIngredients));
    }
  }, [open, itemOrder]);

  const getOption = (id: string) => {
    return category?.options?.find((o) => o.id === id);
  };

  const addOptionChoice = (optID: string, choice: Choice, option: Option) => {
    const newSelectedOptions = [...selectedOptions];
    const opt = newSelectedOptions.find((nso) => nso.id === optID);
    if (opt) {
      if (option.maxChoisesSelectable === 1) {
        opt.choises = [choice];
      } else {
        opt.choises.push(choice);
      }
    } else {
      newSelectedOptions.push({
        id: optID,
        label: option.label,
        choises: [choice],
      } as Option);
    }
    setSelectedOptions(newSelectedOptions);
  };

  const removeOptionChoice = (optID: string, choice: Choice) => {
    const newSelectedOptions = [...selectedOptions];
    const optIndex = newSelectedOptions.findIndex((nso) => nso.id === optID);
    if (optIndex > -1) {
      const opt = newSelectedOptions[optIndex];
      const i = opt.choises.findIndex((c) => c.label === choice.label);
      if (i > -1) {
        opt.choises.splice(i, 1);
        if (opt.choises.length === 0) {
          newSelectedOptions.splice(optIndex, 1);
        }
      }
    }
    setSelectedOptions(newSelectedOptions);
  };

  const getOptionDesc = (options: Option[]) => {
    return _.flatten(options.map((o) => o.choises.map((c) => c.label))).join(', ');
  };

  const removeIngredient = (ingredient: Ingredient) => {
    if (canRemoveIngredient()) {
      setRemovedIngredients([...removedIngredients, ingredient]);
    }
  };

  const canRemoveIngredient = () => {
    return item && _.difference(item.ingredients, removedIngredients).length > 1;
  };

  const getTotalPrice = () => {
    if (item) {
      return item.price + _.sum(_.flatten(selectedOptions.map((o) => o.choises.map((c) => c.price))));
    }
    return 0;
  };

  const canConfirm = () => {
    const selectedOptionsIDS = selectedOptions.map((s) => s.id);
    const optionsIDS = item?.options || [];
    for (const id of _.difference(optionsIDS, selectedOptionsIDS)) {
      if (category?.options?.find((o) => o.id === id)?.required === true) {
        return false;
      }
    }
    return true;
  };

  const upsertOrder = (count: number) => {
    const itemOrderNew = {
      categoryID: category?.id,
      categoryName: category?.name,
      count,
      itemID: item?.id,
      name: item?.name,
      description:
        item?.description || (item?.ingredients && item?.ingredients.length > 0 && item?.ingredients.join(', ')),
      image: item?.image,
      price: item?.price,
      totalPrice: getTotalPrice(),
      selectedOptions,
      removedIngredients,
    } as OrderCartItem;
    onConfirm(itemOrderNew);
  };

  return (
    <>
      <CustomDialog
        fullWidthOnMobile
        onBottom
        noPadding
        open={open}
        onClose={onClose}
        extraButtons={
          <ModalItemFooter
            itemOrder={itemOrder}
            upsertOrder={upsertOrder}
            hideAddButton={hideAddButton}
            canConfirm={canConfirm()}
            category={category}
            onRemoveFromOrder={onRemoveFromOrder}
            totalPrice={getTotalPrice()}
          />
        }
      >
        {item && (
          <div className={classes.baseStyle}>
            <div className={classes.headerStyle}>
              <CustomRow center className={classes.itemNameContainer}>
                <CustomText bold center size={'lg'}>
                  {item.name}
                </CustomText>
              </CustomRow>
              {itemOrder && itemOrder.selectedOptions && itemOrder.selectedOptions.length > 0 && (
                <CustomRow center marginBottom>
                  <CustomText color={'primary'}>{getOptionDesc(itemOrder.selectedOptions)}</CustomText>
                </CustomRow>
              )}
              <CustomRow center marginBottom>
                <CustomText center color={'primary'}>
                  {item.price} €
                </CustomText>
                {item.discountPercentage && (
                  <CustomText bold color={'primary'} style={{marginLeft: '12px'}}>
                    (Sconto -{item.discountPercentage}%)
                  </CustomText>
                )}
              </CustomRow>
            </div>
            {!itemOrder && item.image && item.image.url && (
              <div className={classes.imageContainer}>
                <img src={item.image.url} className={classes.image} />
              </div>
            )}
            <div>
              <div>
                <div>
                  <>
                    {!!item.description && (
                      <div className={classes.description}>
                        <CustomRow marginTop>
                          <CustomText fontFamily={'Open Sans'} color={'primary'}>
                            Descrizione:
                          </CustomText>
                        </CustomRow>
                        <CustomText>{item.description}</CustomText>
                      </div>
                    )}
                    {item.ingredients && item.ingredients.length > 0 && (
                      <div className={classes.ingredients}>
                        <CustomRow marginBottom>
                          <CustomText fontFamily={'Open Sans'} color={'primary'}>
                            Ingredienti:
                          </CustomText>
                          {removedIngredients && removedIngredients.length > 0 && (
                            <div style={{flex: 1, textAlign: 'right'}}>
                              <CustomButton
                                simple
                                color={'primary'}
                                size={'sm'}
                                title={'Ripristina ingredienti'}
                                onClick={() => setRemovedIngredients([])}
                              />
                            </div>
                          )}
                        </CustomRow>
                        <ChipList
                          horizontal={false}
                          data={item.ingredients
                            .filter((i) => !_.includes(removedIngredients, i))
                            .map((i) => ({label: i.name, value: i.id}))}
                          onClose={
                            canRemoveIngredient()
                              ? (i: any) => removeIngredient({name: i.label, id: i.value} as Ingredient)
                              : undefined
                          }
                        />
                      </div>
                    )}
                    {item.options &&
                      item.options.length > 0 &&
                      item.options.map((optID) => (
                        <ItemOption
                          key={optID}
                          option={getOption(optID)}
                          selectedChoices={selectedOptions.find((so) => so.id === optID)?.choises || []}
                          addOptionChoice={(c: Choice, o: Option) => addOptionChoice(optID, c, o)}
                          removeOptionChoice={(c: Choice) => removeOptionChoice(optID, c)}
                        />
                      ))}
                  </>
                </div>
              </div>
            </div>
          </div>
        )}
      </CustomDialog>
    </>
  );
};

type Props = {
  style?: any;
  open?: boolean;
  onClose?: any;
  onConfirm?: any;
  item?: ItemForMenu | null;
  category?: CategoryForMenu | null;
  itemOrder?: OrderCartItem | null;
  onRemoveFromOrder?: any;
  hideAddButton?: boolean;
};

const useStyle = makeStyles({
  baseStyle: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: WHITE,
    height: 'auto',
    width: '100%',
    minWidth: '380px',
    justifyContent: 'center',
    padding: 0,
    '@media (max-width: 480px)': {
      minWidth: 'auto',
    },
  },
  headerStyle: {
    padding: '0 10px',
  },
  itemNameContainer: {
    margin: '6px 24px 6px',
  },
  backButtonContainer: {
    position: 'absolute',
    top: -30,
    right: 4,
  },
  imageContainer: {
    textAlign: 'center',
  },
  image: {
    height: '150px',
    width: '100%',
    minWidth: '150px',
    objectFit: 'cover',
  },
  description: {
    padding: '12px',
  },
  ingredients: {
    padding: '12px',
  },
  optionsStyle: {},
});

export default ModalItem;
