import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import {Menu as MenuIcon} from '@material-ui/icons';
import CustomButton from '../../atoms/CustomButtons/CustomButton';
import {useNavigate} from 'react-router-dom';
import {HOME, LOGIN, USER_ORDERS} from '../../../constants';
import headerSearchStyle from './headerStyle';
import GridContainer from '../../atoms/Grid/GridContainer';
import GridItem from '../../atoms/Grid/GridItem';
import {useDispatch, useSelector} from 'react-redux';
import {State} from '../../../redux/reducers';
import CustomRow from '../../atoms/CustomRow';
import {MenuItem} from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import {logout} from '../../../redux/actions/authActions';
import logo_white from '../../../assets/images/logo_nome_bianco.png';
import HeaderBackButton from '../Header/HeaderBackButton';
import HeaderDrawer from '../Header/HeaderDrawer';

const useStyles = makeStyles(headerSearchStyle);

export default function HeaderRelative({
  color = 'white',
  changeColorOnScroll,
  fixed,
  absolute,
  showBackButton,
  showLoginButton = true,
  showDrawer = true,
  searchValue,
  onSearchChange,
}: Props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((s: State) => s.user.userInfo);
  const isLoggedIn = useSelector((s: State) => s.auth.isLoggedIn);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isPageOnTop, setIsPageOnTop] = useState(true);

  useEffect(() => {
    if (changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange);
    }
    return function cleanup() {
      if (changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange);
      }
    };
  });

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const _logout = () => {
    dispatch(logout());
    setAnchorEl(null);
    setMobileOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const headerColorChange = () => {
    const windowsScrollTop = window.pageYOffset;
    const headerElement = document.body.getElementsByTagName('header')[0];
    if (headerElement) {
      if (windowsScrollTop > changeColorOnScroll.height) {
        headerElement.classList.remove(classes[color]);
        headerElement.classList.add(classes[changeColorOnScroll.color]);
        setIsPageOnTop(false);
      } else {
        headerElement.classList.add(classes[color]);
        headerElement.classList.remove(classes[changeColorOnScroll.color]);
        setIsPageOnTop(true);
      }
    }
  };

  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });

  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        <GridContainer fullWidth>
          {showBackButton && (
            <>
              <GridItem xs={1} />
              <HeaderBackButton />
            </>
          )}
          <GridItem xs={2} noPadding>
            <CustomButton
              onClick={() => navigate(HOME)}
              noShadow
              className={classes.logoContainer}
              color={'transparent'}
              img={<img className={classes.logo} src={logo_white} alt="logo" />}
            />
          </GridItem>
          <Hidden smDown>
            {showLoginButton && (
              <>
                <GridItem
                  xs={showBackButton ? 10 : 11}
                  md={showBackButton ? 9 : 10}
                  centerVertical
                  justifyContent={'end'}
                >
                  {!isLoggedIn ? (
                    <CustomButton
                      size={'lg'}
                      color={'white'}
                      noShadow
                      onClick={() => navigate(LOGIN)}
                      title={'Accedi/Registrati'}
                    />
                  ) : (
                    <CustomRow>
                      <CustomButton
                        ariaControls={'user-menu'}
                        size={'lg'}
                        color={'white'}
                        noShadow
                        onClick={openMenu}
                        title={`BenvenutƏ ${user?.userInfo.name} !`}
                      />
                      <Menu
                        id="user-menu"
                        anchorEl={anchorEl}
                        getContentAnchorEl={null}
                        keepMounted
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={() => navigate(USER_ORDERS)}>Ordini effettuati</MenuItem>
                        <MenuItem onClick={_logout}>Logout</MenuItem>
                      </Menu>
                    </CustomRow>
                  )}
                </GridItem>
              </>
            )}
          </Hidden>
          <Hidden mdUp>
            {showDrawer && (
              <>
                <CustomButton
                  iconColor={'primary'}
                  icon={MenuIcon}
                  onClick={handleDrawerToggle}
                  className={classes.drawerToggle}
                />
                <HeaderDrawer
                  mobileOpen={mobileOpen}
                  showLoginButton={showLoginButton}
                  isLoggedIn={isLoggedIn}
                  handleDrawerToggle={handleDrawerToggle}
                  user={user}
                  logout={_logout}
                  links={[]}
                />
              </>
            )}
          </Hidden>
        </GridContainer>
      </Toolbar>
    </AppBar>
  );
}

type Props = {
  color: 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'transparent' | 'white' | 'rose' | 'dark';
  rightLinks?: any[];
  leftLinks?: any[];
  title?: string;
  fixed?: boolean;
  absolute?: boolean;
  showBackButton?: boolean;
  showLoginButton?: boolean;
  showDrawer?: boolean;
  searchValue?: string;
  onSearchChange?: (v: string) => void;
  changeColorOnScroll?: any;
};
