import {Box, Theme, useMediaQuery, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import React from 'react';
import radial from '../../../../assets/images/ordify-bot/radial.png';
import p1 from '../../../../assets/images/ordify-bot/p1.png';
import p2 from '../../../../assets/images/ordify-bot/p2.png';
import p3 from '../../../../assets/images/ordify-bot/p3.png';
import p4 from '../../../../assets/images/ordify-bot/p4.png';
import p5 from '../../../../assets/images/ordify-bot/p5.png';
import p6 from '../../../../assets/images/ordify-bot/p6.png';
import p7 from '../../../../assets/images/ordify-bot/p7.png';
import p8 from '../../../../assets/images/ordify-bot/p8.png';
import p9 from '../../../../assets/images/ordify-bot/p9.png';
import playerLogo from '../../../../assets/images/ordify-bot/player-logo.png';
import CustomButton from '../../../../components/atoms/CustomButtons/CustomButton';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import {ORDIFY_BOT_PRIMARY} from '../../../../styles/colors';
import {Fonts} from '../../../../styles/font';

type Pos = {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
};

type Props = {};
type PersonProps = {
  src: string;
  width: number;
  pos: Pos;
};
type BoxProps = {
  text: string;
  pos: Pos;
};

const fontFamily: Fonts = 'Montserrat';

const usePersonStyles = makeStyles({
  person: {
    position: 'absolute',
    width: ({width}: PersonProps) => `${width}%`,
    top: ({pos: {top}}: PersonProps) => (top ? `${top}%` : undefined),
    bottom: ({pos: {bottom}}: PersonProps) => (bottom ? `${bottom}%` : undefined),
    left: ({pos: {left}}: PersonProps) => (left ? `${left}%` : undefined),
    right: ({pos: {right}}: PersonProps) => (right ? `${right}%` : undefined),
    '@media (min-width: 1500px)': {
      width: ({width}: PersonProps) => `${width - 1}%`,
    },
  },
});

const Person = (props: PersonProps) => {
  const classes = usePersonStyles(props);
  return <img className={classes.person} src={props.src} alt={'person'} />;
};

const useBoxStyles = makeStyles({
  box: {
    position: 'absolute',
    background: `linear-gradient(180deg, #333333, ${ORDIFY_BOT_PRIMARY})`,
    borderRadius: '10px',
    padding: '10px',
    textAlign: 'center',
    top: ({pos: {top}}: BoxProps) => (top ? `${top}%` : undefined),
    bottom: ({pos: {bottom}}: BoxProps) => (bottom ? `${bottom}%` : undefined),
    left: ({pos: {left}}: BoxProps) => (left ? `${left}%` : undefined),
    right: ({pos: {right}}: BoxProps) => (right ? `${right}%` : undefined),
    '@media (max-width: 767px)': {
      padding: '2px 6px',
    },
  },
});

const RadialBox = (props: BoxProps) => {
  const classes = useBoxStyles(props);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <div className={classes.box}>
      <CustomText fontFamily={'Montserrat'} color={'white'} size={isMobile ? 'xxs' : 'md'} center html>
        {props.text}
      </CustomText>
    </div>
  );
};

const useStyles = makeStyles({
  boxContainerTitle: {
    marginTop: '150px',
    '@media (min-width: 320px) and (max-width: 768px)': {
      marginTop: '100px',
    },
  },
  imgRadialContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  imgRadial: {
    width: '80%',
    height: '100%',
    '@media (max-width: 767px)': {
      width: '100%',
    },
    '@media (min-width: 1500px)': {
      width: '60%',
    },
  },
  imgPlayer: {
    position: 'absolute',
    width: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    objectFit: 'contain',
    '@media (max-width: 767px)': {
      width: '40%',
    },
  },
});

export const TopSection = ({}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const goToRequestTrial = () => {
    const element = document.getElementById('richiedi-prova');
    const to = (element?.getBoundingClientRect().top ?? 0) - 100;
    window.scrollTo({top: to, behavior: 'smooth'});
  };

  const persons: PersonProps[] = [
    {src: p1, width: 6, pos: {top: 16, left: 35}},
    {src: p2, width: 5, pos: {top: 20, left: 65}},
    {src: p3, width: 7, pos: {top: 30, left: 50}},
    {src: p4, width: 5, pos: {top: 35, left: isMobile ? 20 : 30}},
    {src: p5, width: 7, pos: {top: 50, left: isMobile ? 12 : 20}},
    {src: p6, width: 5, pos: {top: 60, left: 37}},
    {src: p7, width: 6, pos: {top: 75, left: 35}},
    {src: p8, width: 5, pos: {top: isMobile ? 60 : 55, left: 65}},
    {src: p9, width: 6, pos: {top: 70, left: 70}},
  ];

  const boxes: BoxProps[] = [
    {text: '100% tasso di risposta', pos: {top: 25, left: isMobile ? 10 : 20}},
    {text: 'Chiamata<br />Risposta<br />Prenotazione<br />Gestione', pos: {top: 35, left: isMobile ? 75 : 70}},
    {text: 'AI Based', pos: {top: 70, left: 48}},
  ];

  return (
    <Box>
      <GridContainer className={classes.boxContainerTitle}>
        <GridItem md={12} centerHorizontal>
          <CustomText fontFamily={fontFamily} color={'ordifyBot'} bold size={'xxl'} center>
            Non perdere più tempo <br />a rispondere al telefono.
          </CustomText>
        </GridItem>
        <GridItem md={12} marginTop={20} centerHorizontal>
          <CustomText fontFamily={fontFamily} size={'md'} center>
            <CustomText fontFamily={fontFamily} bold>
              Ordify BOT
            </CustomText>{' '}
            è l’assistente digitale che{' '}
            <CustomText fontFamily={fontFamily} bold>
              gestisce al posto tuo
            </CustomText>{' '}
            e in {!isMobile && <br />}
            totale autonomia le prenotazioni al tavolo tramite{' '}
            <CustomText fontFamily={fontFamily} bold>
              intelligenza artificiale
            </CustomText>
            .
          </CustomText>
        </GridItem>
        <GridItem md={12} marginTop={40} centerHorizontal>
          <CustomText fontFamily={fontFamily} size={'sm'} center>
            👇 Prenota qui una{' '}
            <CustomText fontFamily={fontFamily} bold size={'sm'}>
              prova gratuita di 30 giorni
            </CustomText>{' '}
            👇
          </CustomText>
        </GridItem>
        <GridItem xs={12} marginTop={20} centerHorizontal>
          <CustomButton
            color={'ordifyBot'}
            size={'lg'}
            title={'Risparmia 1 ora al giorno'}
            fontFamily={fontFamily}
            onClick={goToRequestTrial}
          />
        </GridItem>
      </GridContainer>
      <div className={classes.imgRadialContainer}>
        <img className={classes.imgRadial} src={radial} alt={'radial'} width={100} height={100} />
        {persons.map((person, index) => (
          <Person key={index} {...person} />
        ))}
        <img className={classes.imgPlayer} src={playerLogo} alt={'player'} width={100} height={100} />
        {boxes.map((box, index) => (
          <RadialBox key={index} {...box} />
        ))}
      </div>
    </Box>
  );
};
