import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import {Menu as MenuIcon} from '@material-ui/icons';
import whiteIntersection from '../../../assets/images/landing/header/inter_bianca.svg';
import redIntersections from '../../../assets/images/landing/header/inter_rossa.svg';
import GridContainer from '../../atoms/Grid/GridContainer';
import GridItem from '../../atoms/Grid/GridItem';
import HeaderLinks from './HeaderLinks';
import headerStyle from './headerStyle';
import CustomButton from '../../atoms/CustomButtons/CustomButton';
import {LOGIN, USER_ORDERS} from '../../../constants';
import {navigate} from '../../../services/navigationService';
import {useDispatch, useSelector} from 'react-redux';
import {State} from '../../../redux/reducers';
import {logout} from '../../../redux/actions/authActions';
import CustomRow from '../../atoms/CustomRow';
import {Menu, MenuItem} from '@material-ui/core';
import HeaderBackButton from './HeaderBackButton';
import {UserInfo} from '../../../models/user/user-info.interface';
import HeaderLogo from './HeaderLogo';
import HeaderDrawer from './HeaderDrawer';
import {HeaderLink} from '../types/header-link';
import {Colors} from '../../../styles/colors';
import {Fonts} from '../../../styles/font';

const useStyles = makeStyles(headerStyle);

const ExtraButton = ({isLoggedIn, isPageOnTop, isHome, openMenu, user, fontFamily}: ExtraButtonProps) => (
  <>
    {!isLoggedIn ? (
      <CustomButton
        size={'lg'}
        color={!isPageOnTop || !isHome ? 'primary' : 'white'}
        noShadow
        onClick={() => navigate(LOGIN)}
        title={'Accedi/Registrati'}
        fontFamily={fontFamily}
      />
    ) : (
      <CustomRow>
        <CustomButton
          ariaControls={'user-menu'}
          size={'lg'}
          color={!isPageOnTop || !isHome ? 'primary' : 'white'}
          noShadow
          onClick={openMenu}
          title={`BenvenutƏ ${user?.userInfo.name} !`}
          fontFamily={fontFamily}
        />
      </CustomRow>
    )}
  </>
);

type ExtraButtonProps = {
  showLoginButton?: boolean;
  isLoggedIn: boolean;
  isPageOnTop: boolean;
  isHome: boolean;
  openMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  user: UserInfo | null;
  fontFamily?: Fonts;
};

export default function Header({
  color = 'white',
  links,
  backButton,
  centerElement,
  changeColorOnScroll,
  fixed,
  absolute,
  showLogo = true,
  showLoginButton = true,
  showDrawer = true,
  showBackButton = false,
  showIntersectionImage = false,
  showHeaderLinksOnWeb = true,
  inverted = false,
  isHome = false,
  centerContent = false,
  textColor = 'primary',
  fontFamily,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((s: State) => s.user.userInfo);
  const isLoggedIn = useSelector((s: State) => s.auth.isLoggedIn);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isPageOnTop, setIsPageOnTop] = useState(true);

  useEffect(() => {
    if (changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange);
    }
    return function cleanup() {
      if (changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange);
      }
    };
  });

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const _logout = () => {
    dispatch(logout());
    setAnchorEl(null);
    setMobileOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const headerColorChange = () => {
    const windowsScrollTop = window.pageYOffset;
    const headerElement = document.body.getElementsByTagName('header')[0];
    if (headerElement) {
      if (windowsScrollTop > changeColorOnScroll.height) {
        headerElement.classList.remove(classes[color]);
        headerElement.classList.add(classes[changeColorOnScroll.color]);
        setIsPageOnTop(false);
      } else {
        headerElement.classList.add(classes[color]);
        headerElement.classList.remove(classes[changeColorOnScroll.color]);
        setIsPageOnTop(true);
      }
    }
  };

  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
    [classes.textAlignCenter]: centerContent,
  });

  return (
    <AppBar className={appBarClasses}>
      <Hidden mdDown>
        {showIntersectionImage && isPageOnTop && (
          <img
            className={classes.intersectionWhite}
            style={{transition: 'all 150ms ease 0s'}}
            src={inverted ? whiteIntersection : redIntersections}
            alt={'loading'}
          />
        )}
      </Hidden>
      <Toolbar className={classes.container}>
        {showBackButton && (backButton || <HeaderBackButton color={textColor} />)}
        {(showLogo || centerElement) && (
          <GridContainer>
            <GridItem
              xs={12}
              centerHorizontal={showLogo ? showBackButton || !links || (links.length === 0 && !showLoginButton) : true}
            >
              {showLogo && <HeaderLogo isPageOnTop={isPageOnTop} isHome={isHome} inverted={inverted} />}
              {centerElement}
            </GridItem>
          </GridContainer>
        )}
        <Hidden mdDown>
          <>
            {showHeaderLinksOnWeb && (
              <HeaderLinks
                links={links}
                extraButton={
                  showLoginButton ? (
                    <ExtraButton
                      isHome={isHome}
                      isLoggedIn={isLoggedIn}
                      isPageOnTop={isPageOnTop}
                      openMenu={openMenu}
                      user={user}
                      fontFamily={fontFamily}
                    />
                  ) : undefined
                }
                textColor={
                  !isPageOnTop && changeColorOnScroll && changeColorOnScroll.textColor
                    ? changeColorOnScroll.textColor
                    : textColor
                }
                fontFamily={fontFamily}
              />
            )}
            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              keepMounted
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => navigate(USER_ORDERS)}>Ordini effettuati</MenuItem>
              <MenuItem onClick={_logout}>Logout</MenuItem>
            </Menu>
          </>
        </Hidden>
        <Hidden lgUp>
          {showDrawer && (
            <>
              <CustomButton
                iconColor={
                  changeColorOnScroll && !isPageOnTop
                    ? changeColorOnScroll.textColor ?? 'primary'
                    : textColor ?? 'primary'
                }
                icon={MenuIcon}
                onClick={handleDrawerToggle}
                className={classes.drawerToggle}
              />
              <HeaderDrawer
                mobileOpen={mobileOpen}
                showLoginButton={showLoginButton}
                isLoggedIn={isLoggedIn}
                handleDrawerToggle={handleDrawerToggle}
                user={user}
                logout={_logout}
                links={links}
                textColor={changeColorOnScroll?.textColor ?? textColor}
                fontFamily={fontFamily}
              />
            </>
          )}
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

type Props = {
  color: Colors;
  links?: HeaderLink[];
  backButton?: React.ReactNode;
  centerElement?: React.ReactNode;
  title?: string;
  fixed?: boolean;
  absolute?: boolean;
  changeColorOnScroll?: any;
  showIntersectionImage?: boolean;
  showBackButton?: boolean;
  showLoginButton?: boolean;
  showLogo?: boolean;
  showDrawer?: boolean;
  showHeaderLinksOnWeb?: boolean;
  inverted?: boolean;
  isHome?: boolean;
  centerContent?: boolean;
  textColor?: Colors;
  fontFamily?: Fonts;
};
