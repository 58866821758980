import {Box, useMediaQuery, useTheme} from '@material-ui/core';
import React from 'react';
import r1 from '../../../../assets/images/ordify-bot/r1.png';
import r2 from '../../../../assets/images/ordify-bot/r2.png';
import r3 from '../../../../assets/images/ordify-bot/r3.png';
import r4 from '../../../../assets/images/ordify-bot/r4.png';
import r5 from '../../../../assets/images/ordify-bot/r5.png';
import r6 from '../../../../assets/images/ordify-bot/r6.png';
import CustomRow from '../../../../components/atoms/CustomRow';
import CustomView from '../../../../components/atoms/CustomView';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import CustomCarousel from '../../../../components/molecules/CustomCarousel';
import {ORDIFY_BOT_PRIMARY} from '../../../../styles/colors';
import {Fonts} from '../../../../styles/font';
import {ReviewBox, ReviewPair, type UserProps} from './ReviewBox';

type Props = {};

const fontFamily: Fonts = 'Montserrat';

const reviews: UserProps[] = [
  {
    text: "L'intelligenza artificiale di Ordify che gestisce le prenotazioni telefoniche del mio ristorante è fantastica! Risponde a tutte le chiamate e prende le prenotazioni senza errori, liberando il mio staff per altre attività. Abbiamo risparmiato un sacco di tempo e migliorato il servizio. Consigliatissimo!",
    user: 'Marco G.',
    userImg: r1,
    userLocation: 'Locanda Baia dei Leoni',
  },
  {
    text: "Il servizio di AI per le prenotazioni telefoniche è stato una vera svolta. Prima perdevamo molte chiamate, ora l'AI gestisce tutto rapidamente e senza problemi. Abbiamo notato un notevole risparmio di tempo e una migliore organizzazione. Indispensabile per qualsiasi ristorante!",
    user: 'Luigi M.',
    userImg: r2,
    userLocation: 'Ristorante Croce Bianca',
  },
  {
    text: 'Grazie a Ordify, la gestione delle prenotazioni telefoniche nel nostro ristorante è diventata un gioco da ragazzi. Il bot risponde a ogni chiamata  in modo impeccabile, permettendo al nostro staff di concentrarsi su altre mansioni. Il tempo risparmiato è notevole e il servizio clienti è migliorato. Lo consiglio vivamente!',
    user: 'Giacomo R.',
    userImg: r3,
    userLocation: 'Ristorante La Dolce Vita',
  },
  {
    text: "Ordify ha rivoluzionato il nostro modo di gestire le prenotazioni telefoniche. L'intelligenza artificiale risponde a tutte le chiamate senza errori, liberando il personale per altre attività importanti. Abbiamo riscontrato un grande risparmio di tempo e un miglioramento significativo del servizio clienti. Assolutamente da provare!",
    user: 'Elena B.',
    userImg: r4,
    userLocation: 'Trattoria Bella Italia',
  },
  {
    text: "Ordify Bot ha fatto un'enorme differenza nel nostro locale. Risponde a ogni chiamata e gestisce le prenotazioni perfettamente, lasciando lo staff libero di concentrarsi su altre attività. Il risparmio di tempo è significativo e il servizio clienti è migliorato sensibilmente. Assolutamente raccomandato!",
    user: 'Laura C.',
    userImg: r5,
    userLocation: 'Pizzeria La Favorita',
  },
  {
    text: "L'intelligenza artificiale di Ordify che gestisce le prenotazioni telefoniche del mio ristorante è fantastica! Risponde a tutte le chiamate e prende le prenotazioni senza errori, liberando il mio staff per altre attività. Abbiamo risparmiato un sacco di tempo e migliorato il servizio. Consigliatissimo!",
    user: 'Francesco V.',
    userImg: r6,
    userLocation: 'Osteria Il Gusto',
  },
];

// create review pair array
const reviewsPair: UserProps[][] = reviews.reduce((acc, review, index) => {
  if (index % 2 === 0) {
    acc.push([review, reviews[index + 1]]);
  }
  return acc;
}, [] as UserProps[][]);

export const WhyUs = ({}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box id={'dicono-di-noi'}>
      <CustomView paddingTop={80} paddingHorizontal={isMobile ? 20 : 80}>
        <CustomRow center marginBottom={16}>
          <CustomText bold fontFamily={fontFamily} size={'xxl'} color={'ordifyBot'} center>
            Perché Ordify BOT?
          </CustomText>
        </CustomRow>
        <CustomRow center marginBottom={16}>
          <CustomText fontFamily={fontFamily} center>
            Leggi cosa dicono di noi i ristoratori che hanno scelto di utilizzare il nostro servizio.
          </CustomText>
        </CustomRow>
      </CustomView>
      <CustomView paddingBottom={80} paddingTop={20}>
        <CustomCarousel
          height={isTablet ? '500px' : '400px'}
          activeIndicatorIconButtonStyle={{color: ORDIFY_BOT_PRIMARY}}
          indicatorIconButtonStyle={{padding: 6}}
          indicatorsIconsColor={'ordifyBot'}
          indicators
          autoPlay={false}
        >
          {isTablet
            ? reviews.map((review, index) => (
                <CustomView key={index} paddingHorizontal={60}>
                  <ReviewBox {...review} />
                </CustomView>
              ))
            : reviewsPair.map((pair, index) => (
                <ReviewPair key={index} review1={pair[0]} review2={pair.length === 2 ? pair[1] : undefined} />
              ))}
        </CustomCarousel>
      </CustomView>
    </Box>
  );
};
