import {makeStyles} from '@material-ui/styles';
import CustomCard from '../../../../components/atoms/Card/CustomCard';
import CustomRow from '../../../../components/atoms/CustomRow';
import CustomView from '../../../../components/atoms/CustomView';
import CustomText from '../../../../components/atoms/Typography/CustomText';

export type UserProps = {
  text: string;
  user: string;
  userImg: string;
  userLocation: string;
};

const useStyles = makeStyles({
  reviewPairContainer: {
    padding: '0 50px',
  },
  reviewContainer: {
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minHeight: '300px',
    padding: '10px 20px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
    '@media (max-width: 767px)': {
      minHeight: '400px',
    },
  },
  userImg: {
    borderRadius: '50%',
    width: '75px',
    height: '75px',
  },
});

export const ReviewPair = ({review1, review2}: {review1: UserProps; review2?: UserProps}) => {
  const classes = useStyles();
  return (
    <CustomRow center className={classes.reviewPairContainer}>
      <CustomView marginHorizontal={10}>
        <ReviewBox {...review1} />
      </CustomView>
      {review2 && (
        <CustomView marginHorizontal={10}>
          <ReviewBox {...review2} />
        </CustomView>
      )}
    </CustomRow>
  );
};

export const ReviewBox = ({text, user, userImg, userLocation}: UserProps) => {
  const classes = useStyles();

  return (
    <CustomCard className={classes.reviewContainer}>
      <CustomRow>
        <CustomText fontFamily={'Montserrat'}>{text}</CustomText>
      </CustomRow>
      <CustomRow marginTop={20}>
        <img src={userImg} alt="user" className={classes.userImg} />
        <CustomView marginLeft={12}>
          <CustomRow>
            <CustomText fontFamily={'Montserrat'} color={'ordifyBot'}>
              {user}
            </CustomText>
          </CustomRow>
          <CustomRow>
            <CustomText fontFamily={'Montserrat'}>{userLocation}</CustomText>
          </CustomRow>
        </CustomView>
      </CustomRow>
    </CustomCard>
  );
};
