import {useMediaQuery, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import React from 'react';
import CustomRow from '../../../../components/atoms/CustomRow';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import CustomText from '../../../../components/atoms/Typography/CustomText';

type Props = {
  title: string;
  description: string;
  img: string;
  pos: 'left' | 'right';
};

const useStyles = makeStyles({
  advantageImg: {
    borderRadius: 20,
    width: '100%',
    maxWidth: '600px',
    '@media (max-width: 992px)': {
      width: '80%',
      minWidth: '400px',
    },
    '@media (max-width: 480px)': {
      width: '80%',
      minWidth: 'unset',
    },
  },
});

export const Advantage = ({title, description, img, pos}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <GridContainer justifyContent={'center'} alignItems={'center'}>
      {!isMobile && pos === 'left' && (
        <GridItem md={6} marginTop={20} marginBottom={20} justifyContent={'center'}>
          <img src={img} alt={'advantage'} className={classes.advantageImg} />
        </GridItem>
      )}
      <GridItem md={6} marginTop={20} marginBottom={20}>
        <CustomRow right={pos === 'right'}>
          <CustomText fontFamily={'Montserrat'} size={'lg'} color={'ordifyBot'} bold right={pos === 'right'}>
            {title}
          </CustomText>
        </CustomRow>
        <CustomRow marginTop={20} right={pos === 'right'}>
          <CustomText fontFamily={'Montserrat'} html right={pos === 'right'}>
            {description}
          </CustomText>
        </CustomRow>
      </GridItem>
      {(isMobile || pos === 'right') && (
        <GridItem
          md={6}
          marginTop={20}
          marginBottom={20}
          justifyContent={isMobile ? (pos === 'right' ? 'end' : 'start') : 'center'}
        >
          <img src={img} alt={'advantage'} className={classes.advantageImg} />
        </GridItem>
      )}
    </GridContainer>
  );
};
