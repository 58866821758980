import {Box, useMediaQuery, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import React from 'react';
import Advantage1Image from '../../../../assets/images/ordify-bot/advantage-1.png';
import Advantage2Image from '../../../../assets/images/ordify-bot/advantage-2.png';
import Advantage3Image from '../../../../assets/images/ordify-bot/advantage-3.png';
import CustomRow from '../../../../components/atoms/CustomRow';
import CustomView from '../../../../components/atoms/CustomView';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import {Fonts} from '../../../../styles/font';
import {Advantage} from './Advantage';

type Props = {};

const fontFamily: Fonts = 'Montserrat';

const useStyles = makeStyles({});

const advantages = [
  {
    title: 'Risparmia tempo prezioso',
    description:
      'Immagina di non dover mai più perdere un minuto per rispondere alle chiamate e annotare le prenotazioni dei tavoli. Grazie a Ordify BOT, tutto lo staff avrà accesso immediato a ogni informazione relativa alle prenotazioni ricevute. <b>Dimentica lo stress della gestione e concentrati esclusivamente sul servire al meglio i tuoi clienti.</b>',
    img: Advantage1Image,
  },
  {
    title: 'Incrementa il fatturato',
    description:
      'Non sarà più necessario pagare un dipendente per gestire le chiamate e le prenotazioni. Con Ordify BOT, potrai destinare il personale a compiti più produttivi oppure ridurre le spese eliminando la necessità di un dipendente extra. <b>Ottimizza le risorse e aumenta il fatturato del tuo locale.</b>',
    img: Advantage2Image,
  },
  {
    title: 'Risposta garantita al 100%',
    description:
      'Quando i clienti chiamano il tuo locale, il nostro bot risponde SEMPRE, anche in caso di chiamate multiple simultanee. <b>Non perderai mai più un cliente per una chiamata persa.</b> Assicura un servizio impeccabile e non lasciare mai nessuno in attesa.',
    img: Advantage3Image,
  },
];

export const Advantages = ({}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box id={'vantaggi'}>
      <CustomView paddingVertical={80} paddingHorizontal={isMobile ? 20 : 80}>
        <CustomRow center marginBottom={16}>
          <CustomText bold fontFamily={fontFamily} size={'xxl'} color={'ordifyBot'} center>
            Quali sono i vantaggi?
          </CustomText>
        </CustomRow>
        {advantages.map((advantage, index) => (
          <Advantage key={index} {...advantage} pos={index % 2 === 0 ? 'left' : 'right'} />
        ))}
      </CustomView>
    </Box>
  );
};
